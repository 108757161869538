<script>
// import Switcher from "@/components/switcher";
import Vue from 'vue'
import {AUTH_REQUEST} from '@/store/actions/auth'
import duppla from '@/duppla'
import moment from "moment";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  HomeIcon,
  UserIcon,
  UserCheckIcon,
  MailIcon,
  KeyIcon,
} from "vue-feather-icons";

/**
 * Auth-signup-three component
 */
export default {
  components: {
    // Switcher,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    HomeIcon,
    UserIcon,
    UserCheckIcon,
    MailIcon,
    KeyIcon
  },
  data() {
    return {
      userName: '',
      showContent: false,
      
      token1: '',
      token2: '',
      token3: '',
      token4: '',
      token5: '',
      token6: '',
      
      // token: '',
      validatingToken: false,
      badToken: false,
      badPasted: false
    }
  },
  computed: {
    uuid () {
      return this.$route.query.uuid
    },
    
    token () {
      return this.token1 + this.token2 + this.token3 + this.token4 + this.token5 + this.token6
    }
    
  },
  methods: {
    validateUuid() {
      duppla({ url: 'validateUuid?uuid=' + this.uuid, method: 'GET', data: null })
        .then(response => {
          this.userName = response.data.contact_firstname
          this.showContent = true
          setTimeout(() => {this.$refs.firstToken.focus()}, 100) 
        })
        .catch(error => {
          this.$router.push('/404')
        })
        .finally(() => {})
    },
    sigin () {
      if(!this.validatingToken){
        if (this.$refs.form.checkValidity()) {
          this.validatingToken = true
          this.badToken = false

          let payload = {
            uuid: this.uuid,
            token: this.token
          }
          this.$store.dispatch(AUTH_REQUEST, payload)
            .then(() => {
              this.$router.push({ name: 'dashboard'})
            })
            .catch(error => {
              this.validatingToken = false
              
              this.token1 = ''
              this.token2 = ''
              this.token3 = ''
              this.token4 = ''
              this.token5 = ''
              this.token6 = ''
              // this.token = ''
              this.badToken = true
              this.$refs.firstToken.focus()
            })

          /*
          duppla({ url: 'signin', method: 'POST', data: payload })
            .then(response => {
              console.log("access-token: " + response.data.token)
              var decoded = jwt_decode(response.data.token);
              console.log(decoded);
              var expiryDate = decoded.exp ? moment(moment.unix(decoded.exp)) : null
              if (expiryDate) {
                console.log('expires at: ' + expiryDate)
                let milisToexpire = expiryDate.diff(moment())
                
                //var expireinterval = setInterval(() => {
                //  console.log('expires in: ' + Math.round(expiryDate.diff(moment())/1000) + ' seg')
                //  }, 1000)
                
                var logoutTimeout = setTimeout(() => {
                  console.log('expired!')
                  }, milisToexpire)
              }
              this.$router.push({ name: 'main-form', params: { uuid: this.uuid, token: this.token, access_token: response.data.token } })
            })
            .catch(error => {
              console.error(error)
              this.token1 = ''
              this.token2 = ''
              this.token3 = ''
              this.token4 = ''
              this.token5 = ''
              this.token6 = ''
              this.badToken = true
              this.$refs.firstToken.focus()
            })
            .finally(() => {this.validatingToken = false})
            */
        } else {
          this.$refs.form.reportValidity()
        }
      }
    },
    /*
    validateUuid() {
      let table = 'user'
      let formula = '?filterByFormula=%7Bactivation_uuid%7D%20%3D%20%27' + this.uuid + '%27'
      let fields = '&fields%5B%5D=contact_firstname&fields%5B%5D=link_click_date'

      airtable({ url: table + formula + fields, method: 'GET', data: null })
        .then(response => {
          if (response.data.records.length === 1) {
            this.userId = response.data.records[0].id
            this.userName = response.data.records[0].fields.contact_firstname
            let linkClickDate = response.data.records[0].fields.link_click_date
            if (!linkClickDate) {
              this.updateClickDate(this.userId)
            }
          } else {
            this.$router.push('/404')
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {})
    },
    updateClickDate(id) {
      console.log("Actualizando link_click_date")
      
      let record = {id: id, fields: {link_click_date: new Date()}}
      let obj = {records: []}
      obj.records.push(record)

      airtable({ url: 'user', method: 'PATCH', data: obj })
        .then(response => {
          console.log('Patch Successful: ' + response)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          console.log("Patch done")
        })
    },
    
    validateToken(){
      if(!this.validatingToken){
        if (this.$refs.form.checkValidity()) {
          this.validatingToken = true
          this.badToken = false
          let table = 'user'
          let formula = '?filterByFormula=AND%28%7Bactivation_uuid%7D+%3D+%27' +this.uuid+ '%27%2C%7Btoken%7D+%3D+%27' +this.token+ '%27%29'
          let fields = '&fields%5B%5D=contact_firstname'

          airtable({ url: table + formula + fields, method: 'GET', data: null })
            .then(response => {
              if (response.data.records.length === 1) {
                this.$router.push({ name: 'main-form', params: { uuid: this.uuid, token: this.token } })
              } else if (response.data.records.length === 0) {
                this.token1 = ''
                this.token2 = ''
                this.token3 = ''
                this.token4 = ''
                this.token5 = ''
                this.token6 = ''
                this.badToken = true
                this.$refs.firstToken.focus()
              } else {
                console.error('To many Rows')
              }
            })
            .catch(error => {
              console.error(error)
            })
            .finally(() => {this.validatingToken = false})
        } else {
          this.$refs.form.reportValidity()
        }
      }
    },*/
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="number"]'))
      const index = inputs.indexOf(e.target)
      if (index < inputs.length - 1 && e.data) {
        inputs[index + 1].focus();
        inputs[index + 1].select();
      } else {
        this.sigin()
      }
    },
    onPaste(evt) {
      // console.log("pasted: " + evt.clipboardData.getData('text/plain'))
      this.badPasted = false
      let pasted = evt.clipboardData.getData('text/plain').trim()
      if (pasted.length !== 6 || isNaN(pasted)) {
        this.badPasted = true
      } else {
        let arrPasted = pasted.split("")
        this.token1 = arrPasted[0]
        this.token2 = arrPasted[1]
        this.token3 = arrPasted[2]
        this.token4 = arrPasted[3]
        this.token5 = arrPasted[4]
        this.token6 = arrPasted[5]
        setTimeout(() => {this.sigin()}, 100) 
      }
    }
  },
  created () {
    this.validateUuid()
  },
  mounted() {
    //this.$refs.firstToken.focus()
  }
};
</script>
<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }
</style>

<template>
  <div :class="{invisible: !showContent}">
    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>

    <!-- Hero Start -->
    <section class="bg-home bg-circle-gradiant d-flex align-items-center">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 col-11">
            <div class="card login_page shadow rounded border-0">
              <div class="card-body">
                <h5 class="card-title text-center">Hola {{this.userName}}, gracias por activar tu correo!</h5>
                <h6 class="card-title text-center">Captura el código que te enviamos desde un inicio junto con tu link:</h6>
                <form class="login-form mt-4" ref="form">
                  <div class="row justify-content-center">
                    <!--div class="col-12">
                      <div class="form-group">
                        <div class="position-relative justify-content-center" style="display: flex; text-align: center;">
                          <input type="number" class="form-control align-items-center" v-model="token" ref="tokenInput" size="lg" autocomplete="off" autofocus style="width: 85px;" min="100000" max="999999" required />
                        </div>
                      </div>
                    </div-->
                    <div class="col-12">
                      <div class="form-group">
                        <div class="position-relative justify-content-center" style="display: flex; text-align: center;">
                          
                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token1" @input="focusNext" @paste.prevent="onPaste" ref="firstToken" size="lg" autocomplete="off" autofocus style="width: 40px; height:50px;"/>
                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token2" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>
                            <input type="number" class="form-control align-items-center mr-2 mr-md-4" required v-model="token3" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>
                            
                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token4" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>
                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token5" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>
                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token6" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>

                        </div>
                      </div>
                    </div>
                    <!--div class="col-2">
                      <div class="form-group">
                        <div class="position-relative">
                          <input type="number" class="form-control align-items-center" required v-model="token1" maxlength="1" @input="focusNext" @paste.prevent="onPaste" ref="firstToken" size="lg" autocomplete="off" autofocus style="width: 45px; height:50px;"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <div class="position-relative">
                          <input type="number" class="form-control align-items-center" required v-model="token2" maxlength="1" @input="focusNext" autocomplete="off" style="width: 45px; height:50px;"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <div class="position-relative">
                          <input type="number" class="form-control align-items-center" required v-model="token3" maxlength="1" @input="focusNext" autocomplete="off" style="width: 45px; height:50px;"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <div class="position-relative">
                          <input type="number" class="form-control align-items-center" required v-model="token4" maxlength="1" @input="focusNext" autocomplete="off" style="width: 45px; height:50px;"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <div class="position-relative">
                          <input type="number" class="form-control align-items-center" required v-model="token5" axlength="1" @input="focusNext" autocomplete="off" style="width: 45px; height:50px;"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <div class="position-relative">
                          <input type="number" class="form-control align-items-center" required v-model="token6" maxlength="1" @input="focusNext" autocomplete="off" style="width: 45px; height:50px;"/>
                        </div>
                      </div>
                    </div-->
                    <!--div class="col-md-12">
                      <vue-ladda class="btn btn-primary btn-block btn-secondary btn-lg" @click.prevent="validateToken" :loading="validatingToken">
                        Continuar
                      </vue-ladda>
                    </div-->
                    <div class="col-md-12">
                      <b-btn class="btn btn-primary btn-block" @click.prevent="sigin" :disabled="validatingToken">
                        {{validatingToken?'Validando...':'Continuar'}}
                      </b-btn>
                    </div>
                    <div class="col-md-12 mt-3" v-if="badToken">
                      <div class="alert alert-danger" role="alert">Código Incorrecto! :(</div>
                    </div>
                    <div class="col-md-12 mt-3" v-if="badPasted">
                      <div class="alert alert-warning" role="alert">Asegurate de copiar el codigo de 6 dígitos de forma correcta antes de pegar</div>
                    </div>
                    <div class="mx-auto">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2"
                          >¿Ya has iniciado un proceso?</small
                        >
                        <router-link
                          to="/"
                          class="text-dark font-weight-bold"
                          >Da click aquí</router-link
                        >
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

  </div>
</template>
  